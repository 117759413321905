.wrapper {
	position: absolute;
	top: 70px;
	left: 0;

	width: 100%;
	height: 240px;
	z-index: 15;
	background-color: #FFF;
	border-bottom: 1px solid #B3B2B2;
	padding: 20px;
}

.container {
	width: 1200px;
	margin: auto;
}

.singleItem {
	height: 20px;
	line-height: 12px;
	padding: 0;
	margin: 0;
	cursor: pointer;

	&:hover {
		color: rgb(33, 170, 255)
	}
}