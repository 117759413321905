.textarea {
	width: 100%;
	height: 200px;
	overflow: scroll;
	background-color: #121212;
	font-size: 14px;
	color: white;

	span {
		color: white;
	}
}

.console {
	width: 80%;
}

.collapseHeader {
	border: 1px solid #000;
	border-radius: 8px !important;


	span {
		height: 70px;
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
}

.collapseActiveHeader {
	border: 2px solid #009EFF;

}

.arrow {
	flex-grow: 1;

	display: flex;
	align-items: center;
	width: 5%;
	height: 2px;
	margin-top: 35px;
}

.collapseArrow {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 46px;
	padding-right: 5px;
	height: 100%;
}

.line {
	width: 100%;
	height: 2px;
	background-color: #000
}


.collapseContainer {
	background-color: #fff;
	border: none;
	width: 30%;
}