@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  font-family: "Roboto", Arial, Tahoma, Verdana, sans-serif;
  font-weight: 400;
  font-size: 14px;
  /*line-height: 45px;*/
  /* color: rgba(0, 0, 0, 7); */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span {
  color: rgba(0, 0, 0, 7);
}

.my_custom_class {
  background: greenyellow;
}

.action-buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.edit-button {
  cursor: pointer;
  color: #0288d1;
  text-decoration: underline;
}

.remove-button {
  cursor: pointer;
  color: red;
}

.dnd-sortable-tree_simple_tree-item {
  display: flex;
  justify-content: flex-start;
  padding: 5px 10px;
}

.ant-table-column-sort {
  background-color: transparent !important;
}

.ant-upload-list-item-file {
		width: '100%' !important;
		height:'100%' !important;
		display: flex !important;
		justify-content:'center' !important;
		align-items:'center' !important;

		padding-left: 18px !important;
}

.pickedTableRow {
  background-color: #f0f0f0;

  td {
    padding: 8px 0 !important;
    border: none !important;
  }
}

.defaultTableRow {
  background-color: #fafafa;

  td {
    padding: 8px 0 !important;
    border: none !important;
  }

  &:active {
    background-color: #f0f0f0;
  }
}
