.picked {
	background-color: #f0f0f0;
}

.default {
	&:active {
		background-color: #f0f0f0;
	}
}

.withoutRelation {
	background-color: #FFD9D6;
}

.fieldRelationBtn {
	border: 1px solid #E74C3C;

	&:hover {
		background-color: #E74C3C !important;
		transition: all 0.2s ease-in-out;

		.fieldRelationBtnIcon {

			color: #FFF !important;

		}
	}

}

.fieldRelationBtnIcon {
	color: #E74C3C;
}