.container {
	padding: 0;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	list-style: none;

	li {
		height: 100%;
		margin-right: 15px;
		cursor: pointer;
		display: flex;
		align-items: center;
		font-size: 16px;

		&:hover {
			color: rgb(33, 170, 255);
			transition: all 0.2s ease-in-out;
		}
	}
}

.defaultArrow {
	rotate: 180deg;
	transition: all 0.2s;
	margin-right: 5px;
}

.activeArrow {
	rotate: 0deg;
	transition: all 0.2s;
	margin-right: 5px;
}