.picked {
	background-color: #f0f0f0;

	td {
		padding: 8px 0 !important;
		border: none !important;
	}
}

.default {
	background-color: #fafafa;

	td {
		padding: 8px 0 !important;
		border: none !important;
	}

	&:active {
		background-color: #f0f0f0
	}

}

.errorField {
	background-color: #FFA5A5
}