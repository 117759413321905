.picked {
	background-color: #f0f0f0;


}

.default {
	background-color: #fafafa;


	&:active {
		background-color: #f0f0f0
	}

}